//fallbacks
//for object-fit: cover
(() => {
	if ('objectFit' in document.documentElement.style === false) {
		const coverImages = document.getElementsByClassName('u-image-cover');
		var image;
		for (var i = 0; i < coverImages.length; ++i) {
			image = coverImages[i];
			image.style.background = 'url("' + image.src + '") no-repeat 50%/ cover';
			image.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + image.width + "' height='" + image.height + "'%3E%3C/svg%3E";
		}
    }
})();