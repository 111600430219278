const _ajax = function (dataObject) {
  dataObject = dataObject || {};
  const method = dataObject.method || "GET";
  const dataType = dataObject.dataType;
  const url = dataObject.url;
  const success = dataObject.success || function () {};
  const error = dataObject.error || function () {};
  const forceXDR = "XDomainRequest" in window;
  // eslint-disable-next-line no-undef

  const request = forceXDR ? new XDomainRequest() : new XMLHttpRequest();
  request.open(method, forceXDR ? url.replace("https://", "//") : url, true);
  request.onload = function () {
    if (forceXDR || (request.status >= 200 && request.status < 400)) {
      const response = request.responseText;
      if (request.status === 204) {
        success();
      } else if (!dataType || dataType === "json") {
        let result = null;
        try {
          result = JSON.parse(response);
        } catch (e) {
          // Logger.warn(e);
          success(response);
        }
        if (result !== null) {
          success(result);
        }
      }
    } else {
      error(request.status, JSON.parse(request.responseText).message);
      // mmmmm...
    }
  };
  request.onerror = function () {
    error(request.status, request.statusText);
  };
  const send = function () {
    try {
      if (method !== "POST") {
        request.send();
      } else {
        const data = Object.keys(dataObject.data)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                dataObject.data[key]
              )}`
          )
          .join("&");
        request.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded; charset=UTF-8"
        );
        request.send(data);
      }
    } catch (e) {
      error(request.status, request.statusText, e);
    }
  };
  if (forceXDR) {
    setTimeout(send, 0);
  } else {
    send();
  }
};

const post = function (url, data, success, error, dataType) {
  _ajax({
    url,
    success,
    error,
    dataType,
    method: "POST",
    data,
  });
};
//++++++++++++++++++++++++    L  I  B    +++++++++++++++++++++++++++++++

// utitilties
const addClass = (el, className) => {
  const classList = el.className.split(" ");
  if (classList.indexOf(className) === -1) {
    classList.push(className);
    el.className = classList.join(" ");
  }
};

const removeClass = (el, className) => {
  const classList = el.className.split(" ");
  if (classList.indexOf(className) !== -1) {
    classList.splice(classList.indexOf(className), 1);
    el.className = classList.join(" ");
  }
};
const toggleClass = (el, className) => {
  if (el.className.indexOf(className) === -1) {
    addClass(el, className);
  } else {
    removeClass(el, className);
  }
};

const getText = (i18nKey) => {
  const el = document.getElementById("i18n-" + i18nKey);
  if (el) {
    return el.dataset.translation;
  }
  return "";
};

const getCookieConsent = () => {
	if ('true' !== true) {
		return true;
	}
	if (document.cookie.indexOf('ss360CookieConsent=1') !== -1) {
		return true;
	}
	if (document.cookie.indexOf('ss360CookieConsent=0') !== -1) {
		return false;
	}
	return null;
}

const setCookie = (cookieName, val, days = 365, domain) => {
  var date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "; expires=" + date.toGMTString();
  const domainPart = domain !== undefined && window.location.href.indexOf('http://localhost:') === -1 ? `; domain=${domain}` : '';
  document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(val)}${expires}${domainPart}; path=/; SameSite=Strict`;
};

try {
  gtag('consent','default', {
    'ad_personalization': 'denied',
    'region': ['EEA'],
  });
} catch (err) {
  // ccl
}

// paint flashing fix
(() => {
  const body = document.querySelector("body");
  const removePreloadClass = () => {
    removeClass(body, "preload");
  };
  window.addEventListener("load", (event) => {
    removePreloadClass();
    window.removeEventListener("load", removePreloadClass);
  });
})();

// Menu handler
(() => {
  const toggle = document.getElementById("menu-toggle");
  const pageMenu = document.getElementById("page-menu");
  const pageHeader = document.getElementById("page-header");
  const bodyElement = document.getElementById("body");
  const ACTIVE_CLASS_NAME_HAMBURGER = "hamburger--open";
  const ACTIVE_CLASS_NAME_NAVIGATION = "header__navigation--open";
  const ACTIVE_CLASS_NAME_HEADER = "header--active";
  const NO_SCROLL_CLASS = "u-overflow-hidden";

  toggle.addEventListener("click", () => {
    toggleClass(toggle, ACTIVE_CLASS_NAME_HAMBURGER);
    toggleClass(pageMenu, ACTIVE_CLASS_NAME_NAVIGATION);
    toggleClass(pageHeader, ACTIVE_CLASS_NAME_HEADER);
    toggleClass(bodyElement, NO_SCROLL_CLASS);
  });
})();

// Scroll listener
(() => {
  let isRunning = false;
  const headerElement = document.getElementById("page-header");
  let isHeaderActive;
  const updateHeader = () => {
    const ACTIVE_CLASS_NAME = "header--scrolled";
    let scrollY =
      "scrollY" in window ? window.scrollY : document.documentElement.scrollTop;
    if (scrollY > 40 && (isHeaderActive === undefined || !isHeaderActive)) {
      addClass(headerElement, ACTIVE_CLASS_NAME);
	  isHeaderActive = true;
    } else if (scrollY <= 40 && (isHeaderActive === undefined || isHeaderActive)) {
      removeClass(headerElement, ACTIVE_CLASS_NAME);
	  isHeaderActive = false;
    }
    isRunning = false;
  };
  window.addEventListener("scroll", () => {
    if (isRunning) {
      return;
    }
    isRunning = true;
    setTimeout(updateHeader, 30);
  }, { passive: true });
  setTimeout(updateHeader, 0); // on page load
})();

//Contact form
(() => {
  const successClass = "contact-form--success";
  const errorClass = "contact-form--error";
  const container = document.querySelector(".contact-form");
  const form = document.getElementById("footerContactForm");

  const firstNameInput = document.getElementById("firstName");
  const lastNameInput = document.getElementById("lastName");
  const emailInput = document.getElementById("email");
  const messageInput = document.getElementById("message");

  const referrer = "https://sitesearch360.com/";
  const url = "https://api.sitesearch360.com/public/contact";

  form.addEventListener("submit", (e) => {
    e.preventDefault();

    const data = {
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
      email: emailInput.value,
      message: messageInput.value,
      referrer: referrer,
      ss360SubmitSecurity: "kukukeke",
    };

    const success = () => {
      addClass(container, successClass);
      removeClass(container, errorClass);
    };

    const error = (status, text) => {
      if (text) {
        document.querySelector(".contact-form__error-info p").innerText = text;
      }

      removeClass(container, successClass);
      addClass(container, errorClass);
    };

    post(url, data, success, error, "json");
  });
})();

(() => {
  if (
    !document.querySelector(".js-pricing-card-batman") &&
    !document.querySelector(".js-pricing-table-cell-batman")
  )
    return;


  // pricing table
  (() => {
    if (!document.querySelector(".js-pricing-table-cell-batman")) return;
    // switching plans
    const allCellsOfPlan = {
      batman: [].slice.call(
        document.querySelectorAll(".js-pricing-table-cell-batman")
      ),
      batmanPro: [].slice.call(
        document.querySelectorAll(".js-pricing-table-cell-batmanPro")
      ),
      holmes: [].slice.call(
        document.querySelectorAll(".js-pricing-table-cell-holmes")
      ),
      holmesPro: [].slice.call(
        document.querySelectorAll(".js-pricing-table-cell-holmesPro")
      ),
    };
    const ecomCheckboxes = {
      batman: document.querySelector(
        ".js-pricing-table-batman-ecommerceSearch-checkbox"
      ),
      batmanPro: document.querySelector(
        ".js-pricing-table-batmanPro-ecommerceSearch-checkbox"
      ),
      holmes: document.querySelector(
        ".js-pricing-table-holmes-ecommerceSearch-checkbox"
      ),
      holmesPro: document.querySelector(
        ".js-pricing-table-holmesPro-ecommerceSearch-checkbox"
      ),
    };

    const showPlan = (planId) => {
      allCellsOfPlan[planId].forEach((el) => {
        removeClass(el, "u-d-none");
      });
    };
    const hidePlan = (planId) => {
      allCellsOfPlan[planId].forEach((el) => {
        addClass(el, "u-d-none");
      });
    };
    ecomCheckboxes["batman"].addEventListener("change", (evt) => {
      evt.target.checked = false;
      showPlan("batmanPro");
      hidePlan("batman");
    });
    ecomCheckboxes["batmanPro"].addEventListener("change", (evt) => {
      evt.target.checked = true;
      showPlan("batman");
      hidePlan("batmanPro");
    });
    ecomCheckboxes["holmes"].addEventListener("change", (evt) => {
      evt.target.checked = false;
      showPlan("holmesPro");
      hidePlan("holmes");
    });
    ecomCheckboxes["holmesPro"].addEventListener("change", (evt) => {
      evt.target.checked = true;
      showPlan("holmes");
      hidePlan("holmesPro");
    });
    // price computations
    // batman
    (() => {
      const indexedEntriesSelect = document.querySelector(
        ".js-pricing-table-batman-indexedEntries-select"
      );
      const monthlySearchesSelect = document.querySelector(
        ".js-pricing-table-batman-monthlySearches-select"
      );
      const javascriptCrawlingCheckbox = document.querySelector(
        ".js-pricing-table-batman-javascriptCrawling-checkbox"
      );
      const youTubeCheckbox = document.querySelector(
        ".js-pricing-table-batman-youTube-checkbox"
      );
      const imageOptimizationCheckbox = document.querySelector(
        ".js-pricing-table-batman-imageOptimization-checkbox"
      );
      const geoLocationCheckbox = document.querySelector(
        ".js-pricing-table-batman-geoLocation-checkbox"
      );
      const machineLearnedRankingCheckbox = document.querySelector(
        ".js-pricing-table-batman-machineLearnedRanking-checkbox"
      );
      const searchQualityTrackingCheckbox = document.querySelector(
        ".js-pricing-table-batman-searchQualityTracking-checkbox"
      );

      const priceDisplay = document.querySelector(
        ".js-pricing-table-batman-price"
      );
      const priceDisplayWrapper = document.querySelector(".js-pricing-table-cell-batman .js-price-wrapper");
      const priceFallback = document.querySelector(".js-pricing-table-cell-batman .js-price-fallback");

      const displayPrice = (newPrice) => {
        if (newPrice <= 9999) {
          removeClass(priceDisplayWrapper, "u-d-none");
          addClass(priceFallback, "u-d-none")
        } else {
          addClass(priceDisplayWrapper, "u-d-none");
          removeClass(priceFallback, "u-d-none")
        }
        priceDisplay.innerText = newPrice;
      };

      const currentPlan = PriceUtil.getDefaultPlan('standard');
      const updatePrice = (() => {
        const newPrice = PriceUtil.getCustomPlanPrice(currentPlan, {projectType: 'standard'});
        displayPrice(newPrice);
      });

      indexedEntriesSelect.addEventListener("change", (evt) => {
        currentPlan.pageLimit = +evt.target.value;
        updatePrice();
      });
      monthlySearchesSelect.addEventListener("change", (evt) => {
        currentPlan.queryLimit = +evt.target.value;
        updatePrice();
      });
      javascriptCrawlingCheckbox.addEventListener("change", (evt) => {
        currentPlan.jsCrawling = !!evt.target.checked;
        updatePrice();
      });
      imageOptimizationCheckbox.addEventListener("change", (evt) => {
        currentPlan.imageOptimization = !!evt.target.checked;
        updatePrice();
      });
      youTubeCheckbox.addEventListener("change", (evt) => {
        currentPlan.youTube = !!evt.target.checked;
        updatePrice();
      });
      geoLocationCheckbox.addEventListener("change", (evt) => {
        currentPlan.geoLocation = !!evt.target.checked;
        updatePrice();
      });
      machineLearnedRankingCheckbox.addEventListener("change", (evt) => {
        currentPlan.machineLearnedRanking = !!evt.target.checked;
        updatePrice();
      });
      searchQualityTrackingCheckbox.addEventListener("change", (evt) => {
        currentPlan.searchQualityTracking = !!evt.target.checked;
        updatePrice();
      });
    })();
    // batman pro
    (() => {
      const indexedEntriesSelect = document.querySelector(
        ".js-pricing-table-batmanPro-indexedEntries-select"
      );
      const monthlySearchesSelect = document.querySelector(
        ".js-pricing-table-batmanPro-monthlySearches-select"
      );
      const imageOptimizationCheckbox = document.querySelector(
        ".js-pricing-table-batmanPro-imageOptimization-checkbox"
      );
      const logRetentionSelect = document.querySelector(
        ".js-pricing-table-batmanPro-logRetention-select"
      );
      const geoLocationCheckbox = document.querySelector(
        ".js-pricing-table-batmanPro-geoLocation-checkbox"
      );
      const machineLearnedRankingCheckbox = document.querySelector(
        ".js-pricing-table-batmanPro-machineLearnedRanking-checkbox"
      );
      const searchQualityTrackingCheckbox = document.querySelector(
        ".js-pricing-table-batmanPro-searchQualityTracking-checkbox"
      );

      const priceDisplay = document.querySelector(
        ".js-pricing-table-batmanPro-price"
      );
      const priceDisplayWrapper = document.querySelector(".js-pricing-table-cell-batmanPro .js-price-wrapper");
      const priceFallback = document.querySelector(".js-pricing-table-cell-batmanPro .js-price-fallback");

      const displayPrice = (newPrice) => {
        if (newPrice <= 9999) {
          removeClass(priceDisplayWrapper, "u-d-none");
          addClass(priceFallback, "u-d-none")
        } else {
          addClass(priceDisplayWrapper, "u-d-none");
          removeClass(priceFallback, "u-d-none")
        }
        priceDisplay.innerText = newPrice;
      };

      const currentPlan = PriceUtil.getDefaultPlan('ecom');
      const updatePrice = (() => {
        const newPrice = PriceUtil.getCustomPlanPrice(currentPlan, {projectType: 'ecom'});
        displayPrice(newPrice);
      });

      indexedEntriesSelect.addEventListener("change", (evt) => {
        currentPlan.pageLimit = +evt.target.value;
        updatePrice();
      });
      monthlySearchesSelect.addEventListener("change", (evt) => {
        currentPlan.queryLimit = +evt.target.value;
        updatePrice();
      });
      imageOptimizationCheckbox.addEventListener("change", (evt) => {
        currentPlan.imageOptimization = !!evt.target.checked;
        updatePrice();
      });
      logRetentionSelect.addEventListener("change", (evt) => {
        currentPlan.logRetentionDays = +evt.target.value * 365 / 12;
        updatePrice();
      });
      geoLocationCheckbox.addEventListener("change", (evt) => {
        currentPlan.geoLocation = !!evt.target.checked;
        updatePrice();
      });
      machineLearnedRankingCheckbox.addEventListener("change", (evt) => {
        currentPlan.machineLearnedRanking = !!evt.target.checked;
        updatePrice();
      });
      searchQualityTrackingCheckbox.addEventListener("change", (evt) => {
        currentPlan.searchQualityTracking = !!evt.target.checked;
        updatePrice();
      });
    })();
  })();

  // pricing cards
  (() => {
    if (!document.querySelector(".js-pricing-card-batman")) return;
    // switching plans
    const planCard = {
      batman: document.querySelector(".js-pricing-card-batman"),
      batmanPro: document.querySelector(".js-pricing-card-batmanPro"),
      holmes: document.querySelector(".js-pricing-card-holmes"),
      holmesPro: document.querySelector(".js-pricing-card-holmesPro"),
    };

    const cardEcomCheckboxes = {
      batman: document.querySelector(
        ".js-pricing-card-batman-ecommerceSearch-checkbox"
      ),
      batmanPro: document.querySelector(
        ".js-pricing-card-batmanPro-ecommerceSearch-checkbox"
      ),
      holmes: document.querySelector(
        ".js-pricing-card-holmes-ecommerceSearch-checkbox"
      ),
      holmesPro: document.querySelector(
        ".js-pricing-card-holmesPro-ecommerceSearch-checkbox"
      ),
    };

    const showCard = (planId) => {
      removeClass(planCard[planId], "u-d-none");
    };
    const hideCard = (planId) => {
      addClass(planCard[planId], "u-d-none");
    };

    cardEcomCheckboxes["batman"].addEventListener("change", (evt) => {
      evt.target.checked = false;
      showCard("batmanPro");
      hideCard("batman");
    });
    cardEcomCheckboxes["batmanPro"].addEventListener("change", (evt) => {
      evt.target.checked = true;
      showCard("batman");
      hideCard("batmanPro");
    });
    cardEcomCheckboxes["holmes"].addEventListener("change", (evt) => {
      evt.target.checked = false;
      showCard("holmesPro");
      hideCard("holmes");
    });
    cardEcomCheckboxes["holmesPro"].addEventListener("change", (evt) => {
      evt.target.checked = true;
      showCard("holmes");
      hideCard("holmesPro");
    });
    // price computations
    // batman
    (() => {
      const indexedEntriesSelect = document.querySelector(
        ".js-pricing-card-batman-indexedEntries-select"
      );
      const monthlySearchesSelect = document.querySelector(
        ".js-pricing-card-batman-monthlySearches-select"
      );
      const javascriptCrawlingCheckbox = document.querySelector(
        ".js-pricing-card-batman-javascriptCrawling-checkbox"
      );
      const youTubeCheckbox = document.querySelector(
        ".js-pricing-card-batman-youTube-checkbox"
      );
      const imageOptimizationCheckbox = document.querySelector(
        ".js-pricing-card-batman-imageOptimization-checkbox"
      );
      const geoLocationCheckbox = document.querySelector(
        ".js-pricing-card-batman-geoLocation-checkbox"
      );
      const machineLearnedRankingCheckbox = document.querySelector(
        ".js-pricing-card-batman-machineLearnedRanking-checkbox"
      );
      const searchQualityTrackingCheckbox = document.querySelector(
        ".js-pricing-card-batman-searchQualityTracking-checkbox"
      );

      const priceDisplay = document.querySelector(
        ".js-pricing-card-batman-price"
      );
      const priceDisplayWrapper = document.querySelector(".js-pricing-card-batman .js-price-wrapper");
      const priceFallback = document.querySelector(".js-pricing-card-batman .js-price-fallback");

      const displayPrice = (newPrice) => {
        if (newPrice <= 9999) {
          removeClass(priceDisplayWrapper, "u-d-none");
          addClass(priceFallback, "u-d-none")
        } else {
          addClass(priceDisplayWrapper, "u-d-none");
          removeClass(priceFallback, "u-d-none")
        }
        priceDisplay.innerText = newPrice;
      };

      const currentPlan = PriceUtil.getDefaultPlan('standard');
      const updatePrice = (() => {
        const newPrice = PriceUtil.getCustomPlanPrice(currentPlan, {projectType: 'standard'});
        displayPrice(newPrice);
      });

      indexedEntriesSelect.addEventListener("change", (evt) => {
        currentPlan.pageLimit = +evt.target.value;
        updatePrice();
      });
      monthlySearchesSelect.addEventListener("change", (evt) => {
        currentPlan.queryLimit = +evt.target.value;
        updatePrice();
      });
      javascriptCrawlingCheckbox.addEventListener("change", (evt) => {
        currentPlan.jsCrawling = !!evt.target.checked;
        updatePrice();
      });
      imageOptimizationCheckbox.addEventListener("change", (evt) => {
        currentPlan.imageOptimization = !!evt.target.checked;
        updatePrice();
      });
      youTubeCheckbox.addEventListener("change", (evt) => {
        currentPlan.youTube = !!evt.target.checked;
        updatePrice();
      });
      geoLocationCheckbox.addEventListener("change", (evt) => {
        currentPlan.geoLocation = !!evt.target.checked;
        updatePrice();
      });
      machineLearnedRankingCheckbox.addEventListener("change", (evt) => {
        currentPlan.machineLearnedRanking = !!evt.target.checked;
        updatePrice();
      });
      searchQualityTrackingCheckbox.addEventListener("change", (evt) => {
        currentPlan.searchQualityTracking = !!evt.target.checked;
        updatePrice();
      });
    })();

    // batman pro
    (() => {
      const indexedEntriesSelect = document.querySelector(
        ".js-pricing-card-batmanPro-indexedEntries-select"
      );
      const monthlySearchesSelect = document.querySelector(
        ".js-pricing-card-batmanPro-monthlySearches-select"
      );
      const imageOptimizationCheckbox = document.querySelector(
        ".js-pricing-card-batmanPro-imageOptimization-checkbox"
      );
      const geoLocationCheckbox = document.querySelector(
        ".js-pricing-card-batmanPro-geoLocation-checkbox"
      );
      const machineLearnedRankingCheckbox = document.querySelector(
        ".js-pricing-card-batmanPro-machineLearnedRanking-checkbox"
      );
      const searchQualityTrackingCheckbox = document.querySelector(
        ".js-pricing-card-batmanPro-searchQualityTracking-checkbox"
      );

      const priceDisplay = document.querySelector(
        ".js-pricing-card-batmanPro-price"
      );
      const priceDisplayWrapper = document.querySelector(".js-pricing-card-batmanPro .js-price-wrapper");
      const priceFallback = document.querySelector(".js-pricing-card-batmanPro .js-price-fallback");

      const displayPrice = (newPrice) => {
        if (newPrice <= 9999) {
          removeClass(priceDisplayWrapper, "u-d-none");
          addClass(priceFallback, "u-d-none")
        } else {
          addClass(priceDisplayWrapper, "u-d-none");
          removeClass(priceFallback, "u-d-none")
        }
        priceDisplay.innerText = newPrice;
      };
      const currentPlan = PriceUtil.getDefaultPlan('ecom');
      const updatePrice = (() => {
        const newPrice = PriceUtil.getCustomPlanPrice(currentPlan, {projectType: 'ecom'});
        displayPrice(newPrice);
      });

      indexedEntriesSelect.addEventListener("change", (evt) => {
        currentPlan.pageLimit = +evt.target.value;
        updatePrice();
      });
      monthlySearchesSelect.addEventListener("change", (evt) => {
        currentPlan.queryLimit = +evt.target.value;
        updatePrice();
      });
      imageOptimizationCheckbox.addEventListener("change", (evt) => {
        currentPlan.imageOptimization = !!evt.target.checked;
        updatePrice();
      });
      geoLocationCheckbox.addEventListener("change", (evt) => {
        currentPlan.geoLocation = !!evt.target.checked;
        updatePrice();
      });
      machineLearnedRankingCheckbox.addEventListener("change", (evt) => {
        currentPlan.machineLearnedRanking = !!evt.target.checked;
        updatePrice();
      });
      searchQualityTrackingCheckbox.addEventListener("change", (evt) => {
        currentPlan.searchQualityTracking = !!evt.target.checked;
        updatePrice();
      });
    })();
  })();
})();

// pricing faq section click listneres
(() => {
  const BTN_CLASS = "faq__button";
  const ACTIVE_CLASS = "faq--active";

  const faqButtons = [].slice.call(document.querySelectorAll(`.${BTN_CLASS}`));
  faqButtons.forEach((btn) => {
    btn.addEventListener("click", () => {
      toggleClass(btn.parentElement, ACTIVE_CLASS);
      // toggleCollapse(btn.nextSibling);
    });
  });
})();

//ebook cto on click listner
(() => {
  const TRIGGER_EL_CLASS = "ebook-toc__title";
  const TOC_LIST_CLASS = "ebook-toc__list";
  const ACTIVE_TITLE_CLASS = "ebook-toc__title--active";
  const ACTIVE_LIST_CLASS = "ebook-toc__list--active";

  const tocTitle = document.querySelector(`.${TRIGGER_EL_CLASS}`);
  const tocList = document.querySelector(`.${TOC_LIST_CLASS}`);
  const tocLinks = [].slice.call(
    document.querySelectorAll(`.${TOC_LIST_CLASS} a`)
  );

  if (tocTitle) {
    const clickListener = () => {
      toggleClass(tocTitle, ACTIVE_TITLE_CLASS);
      toggleClass(tocList, ACTIVE_LIST_CLASS);
    };

    tocTitle.addEventListener("click", clickListener);
    tocLinks.forEach((link) => {
      link.addEventListener("click", clickListener);
    });
  }
})();

//chat plugin
(() => {
  // Helpscout form
  const realBeaconInit = (open) => {
    setTimeout(() => {
      // delay loading of the helpscout beacon if this is the first visit
	  if (getCookieConsent()) {
		  setCookie("ss360BeaconLoaded", "1");
	  }
      !(function (e, t, n) {
        function a() {
          var e = t.getElementsByTagName("script")[0],
            n = t.createElement("script");
          (n.type = "text/javascript"),
            (n.async = !0),
            (n.src = "https://beacon-v2.helpscout.net"),
            e.parentNode.insertBefore(n, e);
        }
        if (
          ((e.Beacon = n =
            function (t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
          (n.readyQueue = []),
          "complete" === t.readyState)
        )
          return a();
        e.attachEvent
          ? e.attachEvent("onload", a)
          : e.addEventListener("load", a, !1);
      })(window, document, window.Beacon || function () {});
      window.Beacon("init", "00968a60-2240-411c-b46d-b3cf3cc48deb");
      window.Beacon("once", "ready", () => {
        if (document.querySelector(".fake-beacon")) {
          const loadingClass = "fake-beacon--loading";
          const fakeBeacon = document.querySelector(".fake-beacon");
          fakeBeacon.style.display = "none";
          removeClass(fakeBeacon, loadingClass);
        }
      });
      if (open) {
        window.Beacon("open");
      }
    }, 0);
  };
  const fakeBeaconInit = () => {
    const fakeBeacon = document.querySelector(".fake-beacon");
    fakeBeacon.style.display = "block";
    const scaleUpFake = () => {
      fakeBeacon.style.transform = "scale(1)";
      window.removeEventListener("load", scaleUpFake);
    };
    window.addEventListener("load", scaleUpFake);

    const actionHandler = () => {
      const loadingClass = "fake-beacon--loading";
      addClass(fakeBeacon, loadingClass);
      realBeaconInit(true);
      fakeBeacon.removeEventListener("click", actionHandler);
    };
    fakeBeacon.addEventListener("click", actionHandler);
  };

  // const wasLoaded = (document.cookie || "").split(";").filter((cookie) => (cookie || "").indexOf("ss360BeaconLoaded") !== -1)[0] !== undefined;
  const wasLoaded = "Beacon" in window;
  if (wasLoaded) {
    realBeaconInit();
  } else {
    fakeBeaconInit();
  }
})();

// sign up form
(() => {
  const BASE_URL = "https://api.sitesearch360.com";
  const forms = [].slice.call(
    document.getElementsByClassName("signup-form-wrap")
  );
  forms.forEach((form) => {
    let domain;
    let email;
    const domainForm = form.querySelector(".domain-form");
    const emailFormWrap = form.querySelector(".email-form");
    const emailForm = emailFormWrap.querySelector("form");
    const domainFormSubmit = domainForm.querySelector(".signup-form__submit");
    const emailFormSubmit = emailForm.querySelector(".signup-form__submit");
    const loader = form.querySelector(".signup-form__loader");
    let signedUp = false;

    const domainInput = domainForm.querySelector(".signup-form__input");
    const emailInput = emailForm.querySelector(".signup-form__input");

    // const domainIsValid = (domain) => !(domain.match(/^(https?:\/\/)?[A-Za-z0-9-._]+\.[A-Za-z]{2,10}/) === null) ;
    const domainIsValid = (domainString) => {
      const match = domainString.match(
        /^(https?:\/\/)?(w{1,3}\.)?(?<domain>[A-Za-z0-9-._]+\.[A-Za-z]{2,10})(\/[^#?&\/]{1,30}){0,8}\/{0,1}$/
      );
      if (match === null) {
        return false;
      }
      const domain = match.groups.domain;

      const blacklist = [
        "amazon.",
        "google.",
        "facebook.",
        "youtube.",
        "ebay.",
        "ebay.",
        "yahoo.",
        "hotmail.",
        "bing.",
        "yandex.",
        "linkedin.",
        "reddit.",
        "tiktok.",
        "tinder.",
        "tumblr.",
        "sitesearch360.com",
        "control.sitesearch360.com",
        "pornhub.",
        "localhost.",
        "example.",
        "test.",
        "semknox.com",
        "javankat.cz",
        "javankat.cz",
        "eltesteroooo.borec.cz",
        "com.semknox.com",
        "app.sitesearch360.com",
      ];
      for (let i = 0; i < blacklist.length; ++i) {
        if (domain.includes(blacklist[i])) return false;
      }
      return true;
    };

    const emailIsValid = (email) =>
      !(
        email.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) === null
      );

    domainInput.addEventListener("input", (e) => {
      if (!domainIsValid(e.target.value)) {
        domainFormSubmit.setAttribute("disabled", "disabled");
      } else {
        domainFormSubmit.removeAttribute("disabled");
      }
    });
    emailInput.addEventListener("input", (e) => {
      if (!emailIsValid(e.target.value)) {
        emailFormSubmit.setAttribute("disabled", "disabled");
      } else {
        emailFormSubmit.removeAttribute("disabled");
      }
    });

    domainInput.addEventListener("keyup", () => {
      if (!window.siteInputGaEventSent) {
        try {
          gtag("event", "lead_start", {
            event_category: "speedRegistration",
          });
        } catch (e) {}

        window.siteInputGaEventSent = true;
      }
    });
    emailInput.addEventListener("keyup", () => {
      if (!window.emailInputGaEventSent) {
        gtag('consent','default', {
          'ad_personalization': 'denied',
          'region': ['EEA'],
        });
      } else {
        try {
          gtag("event", "lead_step_2_start", {
            event_category: "speedRegistration",
          });
        } catch (e) {}

        window.emailInputGaEventSent = true;
      }
    });

    const hideErrorMessages = () => {
      [].slice
        .call(form.getElementsByClassName("signup-form__message"))
        .forEach((message) => {
          removeClass(message, "signup-form__message--active");
        });
    };
    domainForm.addEventListener("submit", (e) => {
      e.preventDefault();
      e.stopPropagation();
      domain = domainInput.value;
      hideErrorMessages();
      if (!domainIsValid(domain)) {
        addClass(
          form.querySelector(".signup-form__message--invalid-domain"),
          "signup-form__message--active"
        );
        return;
      }
      domainFormSubmit.setAttribute("disabled", "disabled");

      const successCallback = () => {
        removeClass(
          form.querySelector(".signup-form__message--error"),
          "signup-form__message--active"
        );
        removeClass(domainForm, "signup-form--active");
        addClass(emailFormWrap, "signup-form--active");
        emailInput.focus();
        try {
          gtag("event", "lead_step_1_complete", {
            event_category: "speedRegistration",
          });
        } catch (e) {}
      };

      const errorCallback = () => {
        domainFormSubmit.removeAttribute("disabled");
        addClass(
          form.querySelector(".signup-form__message--error"),
          "signup-form__message--active"
        );
      };

      post(
        `${BASE_URL}/sites?site=${encodeURIComponent(domain)}`,
        {},
        (data) => {
          if (data.status === "success") {
            successCallback();
          } else {
            successCallback
            //errorCallback();
          }
        },
        successCallback
        //errorCallback
      );
    });
    emailForm.addEventListener("submit", (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (signedUp) return;
      emailFormSubmit.setAttribute("disabled", "disabled");
      hideErrorMessages();
      email = emailForm.querySelector(".signup-form__input").value;
      domain = domainForm.querySelector(".signup-form__input").value;
      const qs =
        "email=" +
        window.encodeURIComponent(email) +
        "&domain=" +
        window.encodeURIComponent(domain);
        try {
          gtag("event", "lead_conversion", {
            event_category: "speedRegistration",
          });
        } catch(err) {
          // ccl
        }
      window.location.href = "https://app.sitesearch360.com/signup?" + qs;
    });
  });
})();

// Lazyload
(() => {
  let nodes = [].slice.call(document.querySelectorAll(".lazy"));
  if (nodes.length === 0) {
    return;
  }

  const removeLazyClass = (image) => {
    removeClass(image, "lazy");
  };

  const load = (image) => {
    const src = image.dataset["src"];
    if (src === null || src === undefined) return;
    image.removeAttribute("data-src");
    const img = new Image();
    img.onload = function () {
      removeLazyClass(image);
    };
    img.src = src;
    image.setAttribute("src", src);
    // image.removeAttribute('data-src');
  };

  const loadIframe = (iframe) => {
    removeLazyClass(iframe);
    const src = iframe.dataset["src"];
    if (src === undefined || src === null) return;
    iframe.removeAttribute("data-src");
    iframe.setAttribute("src", src);
  };

  const loadVideo = (video) => {
	  removeLazyClass(video);
	  const sources = [].slice.call(video.querySelectorAll('source'));
	  sources.forEach((source) => {
		const src = source.dataset["src"];
		if (src === undefined || src === null) return;
		source.removeAttribute("data-src");
		source.setAttribute("src", src);
	  });
	  video.load();
  };

  const loadScript = (node) => {
    removeLazyClass(node);
    const src = node.dataset["src"];
    if (src === undefined || src === null) return;
    node.removeAttribute("data-src");
    const s = document.createElement("script");
    s.setAttribute("async", "async");
    s.src = src;
    node.parentNode.appendChild(s);
  };

  const LazyLoader = (nodes, rootMargin) => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (entry.target.tagName === "IFRAME") {
                loadIframe(entry.target);
              } else if (entry.target.tagName === "IMG") {
                load(entry.target);
              } else if (entry.target.tagName === 'VIDEO') {
				loadVideo(entry.target);
			  } else  {
                loadScript(entry.target);
              }
              observer.unobserve(entry.target);
            }
          });
        },
        {
          rootMargin: `${rootMargin}px`,
        }
      );
      nodes.forEach((img) => {
        observer.observe(img);
      });
    } else {
      // fallback for browsers not supporting IntersectionObserver
      let active = false;

      const lazyLoad = function () {
        if (active === false) {
          active = true;

          setTimeout(() => {
            nodes.forEach((node) => {
              if (
                node.getBoundingClientRect().top <=
                  window.innerHeight + rootMargin &&
                node.getBoundingClientRect().bottom >= 0 &&
                getComputedStyle(node).display !== "none"
              ) {
                if (node.tagName === "IFRAME") {
                  loadIframe(node);
                } else if (node.tagName === "IMG") {
                  load(node);
                } else {
                  loadScript(node);
                }
                nodes = nodes.filter((otherImage) => node !== otherImage);

                if (nodes.length === 0) {
                  window.document.removeEventListener("scroll", lazyLoad);
                  window.removeEventListener("scroll", lazyLoad);
                  window.removeEventListener("orientationchange", lazyLoad);
                  window.removeEventListener("resize", lazyLoad);
                }
              }
            });

            active = false;
          }, 200);
        }
      };

      window.document.addEventListener("scroll", lazyLoad, { passive: true });
      window.addEventListener("scroll", lazyLoad, { passive: true });
      window.addEventListener("orientationchange", lazyLoad, { passive: true });
      window.addEventListener("resize", lazyLoad, { passive: true });
      lazyLoad();
    }
  };
  const rootMargin = ('NetworkInformation' in window) && window.NetworkInformation.saveData === true ? 300 : 1000;
  setTimeout(() => {
    LazyLoader([].slice.call(nodes), rootMargin);
  }, 5);
})();

//webinar form
(() => {
  const SUCCESS_CLASS = "webinar--success";
  const ERROR_CLASS = "webinar--error";
  const COOKIE_NAME = "webinar_1";

  const container = document.querySelector(".webinar");
  const form = document.getElementById("webinar-form");

  if (!form) {
    return;
  }

  const alreadySubmitted =
    document.cookie.match(new RegExp(COOKIE_NAME)) !== null;
  if (alreadySubmitted) {
    addClass(container, SUCCESS_CLASS);
    return;
  }

  const firstNameInput = document.getElementById("webinarFirstName");
  const lastNameInput = document.getElementById("webinarLastName");
  const emailInput = document.getElementById("webinarEmail");
  const urlInput = document.getElementById("webinarUrl");
  const message = document.getElementById("webinarMessage");

  const success = () => {
	if (getCookieConsent()) {
		setCookie("webinar_1", true);
	}
    removeClass(container, ERROR_CLASS);
    addClass(container, SUCCESS_CLASS);
  };

  const error = (status, text) => {
    removeClass(container, SUCCESS_CLASS);
    addClass(container, ERROR_CLASS);
    if (text) {
      document.querySelector(".webinar__error-message").innerText =
        errorMessage;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const url = "https://api.sitesearch360.com/public/webinar";
    const data = {
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
      email: emailInput.value,
      domain: urlInput.value,
      questions: message.value,
      webinarId: 1,
    };
    post(url, data, success, error, "json");
  };

  form.addEventListener("submit", submitHandler);
})();

//ebook form
(() => {
  const wrapper = document.querySelector(".ebook-form-wrapper");

  const successClass = "contact-form--success";
  const errorClass = "contact-form--error";
  const showFormClass = "ebook-form-wrapper--show-form";
  const showFormButton = document.getElementById("show-ebook-form-btn");

  const clickHandler = () => {
    addClass(wrapper, showFormClass);
  };
  if (showFormButton !== null) {
	  showFormButton.addEventListener("click", clickHandler);
  }
  const container = wrapper !== null ? wrapper.querySelector(".contact-form") : document.querySelector('main .contact-form');
  const form = document.getElementById("subscriptionForm");
  if (form === null) {
	return;
  }

  const firstNameInput = document.getElementById("subscriptionFirstName");
  const lastNameInput = document.getElementById("subscriptionLastName");
  const emailInput = document.getElementById("subscriptionEmail");
  const jobRoleInput = document.getElementById("subscriptionJobRole");
  const _searchEngineInput = document.getElementById("subscriptionSearchEngine");
  const _specifySearchEngineInput = document.getElementById("subscriptionSpecifySearchEngine");
  const _specifySearchEngineFieldset = document.getElementById("specifySearchEngineFieldset");
  const list = form.querySelector("input[name=\"list\"]");
  const companyNameInput = document.getElementById('subscriptionCompanyName');
  const companyWebsiteInput = document.getElementById('subscriptionCompanyWebsite');

  if (companyWebsiteInput !== null) {
	const urlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
	companyWebsiteInput.addEventListener('keyup', (e) => {
		if (e.key === 'Enter' || e.which === 13 || e.code === 'Enter' || e.key === 'Tab' || e.which === 9 || e.code === 'Tab') { // don't revalidate on enter click/tab focus
			return;
		}
		const input = e.target;
		const value = input.value;
		const isValidDomain = value.match(urlRegExp) !== null;
		if (!isValidDomain) {
			try {
				input.setCustomValidity('Provide a valid domain name, e.g. www.company.com');
			} catch (ex) {
				console.warn(ex);
			}
		} else {
			try {
				input.setCustomValidity('');
			} catch (ex) {
				console.warn(ex);
			}
		}
	})
  }

  let searchEngineInput = _searchEngineInput;
  _searchEngineInput.addEventListener("change", (e) => {
    if (e.target.value == "Other") {
      removeClass(_specifySearchEngineFieldset, "u-d-none");
      searchEngineInput = _specifySearchEngineInput;
    } else {
      addClass(_specifySearchEngineFieldset, "u-d-none");
      searchEngineInput = _searchEngineInput;
    }
  });

  form.addEventListener("submit", (e) => {
    e.preventDefault();
    const url = "https://api.sitesearch360.com/public/subscribe";

    const data = {
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
      email: emailInput.value,
      jobRole: jobRoleInput.value,
      searchEngines: searchEngineInput.value,
	  list: list.value,
	  companyName: companyNameInput !== null ? companyNameInput.value : '',
	  companyWebsite: companyWebsiteInput !== null ? companyWebsiteInput.value : '',
      ss360SubmitSecurity: "kukukeke",
    };

    const success = () => {
      addClass(container, successClass);
      removeClass(container, errorClass);
    };

    const error = (status, text) => {
      if (text) {
        wrapper.querySelector(".contact-form__error-info p").innerText = text;
      }
      removeClass(container, successClass);
      addClass(container, errorClass);
    };

    //TODO: replace when the ENDPOINT is ready
    post(url, data, success, error, "json");
    // success();
  });
})();

// Register service worker
(() => {
  if ("serviceWorker" in window.navigator) {
    let lang = "en";
    if (window.location.href.indexOf("/fr/") !== -1) {
      lang = "fr";
    } else if (window.location.href.indexOf("/de/") !== -1) {
      lang = "de";
    }
    window.addEventListener("load", function () {
      navigator.serviceWorker.register(
        `/serviceWorker.js?ts=#BUILD_TIMESTAMP#&lang=${lang}`
      );
    });
  }
})();

// Make sure the user can switch language
(() => {
	setCookie("_ss360-loaded", "1");
})();

// Search
(() => {
  if (
    window.location.href.indexOf("/de/") !== -1 ||
    window.location.href.indexOf("/fr/") !== -1
  ) {
    // [].splice.call(document.querySelectorAll('.header__mobile-search-toggle, .menu__item-wrap--search')).forEach((node) => {
    // 	addClass(node, 'hidden');
    // })
    return;
  }
  const allowCookies = getCookieConsent();
  window.ss360Config = {
    siteId: "sitesearch360.com_11",
    searchBox: {
	  selector: '#unknown',
      searchButtonLabel: "Search",
      placeholder: "Search for features, docs,…",
    },
    results: {
      placeholderImage: "/assets/global/search-result-placeholder.svg",
      searchQueryParamName: "q",
    },
    layout: {
      navigation: {
        flatTabs: true
      }
    },
    suggestions: {
      trigger: ".js-search-trigger",
      maxWidth: 800,
      maxSearchHistoryEntries: 10,
      viewAllLabel: null,
      source: {
        emptyState: {
          layoutType: 2,
          content: [
            {
              type: "dataSet",
              layout: {
                type: "horizontalList",
                icon: "ss360:search",
              },
              content: [
                { title: "what is site search" },
                { title: "how to add search" },
                { title: "pricing" },
              ],
            },
            {
              type: "searchHistory",
              layout: {
                icon: "ss360:history",
              },
            },
            {
              type: "dataSet",
              layout: {
                showSnippet: true,
              },
              categoryStyle: "chips",
              content: [
                {
                  title:
                    "How a site search tool can improve your conversion rates",
                  image:
                    "https://www.sitesearch360.com/assets/blog/how-a-site-search-tool-can-improve-your-conversion-rate/How%20You%20Can%20Get%20More%20Conversions%20with%20the%20Right%20Site%20Search%20Tool_canva.png",
                  snippet:
                    "If your customers can't find the product, service, or information they want quickly and easily, they'll go elsewhere. No search results, no sales.",
                  link: "https://www.sitesearch360.com/blog/how-a-site-search-tool-can-improve-your-conversion-rate/",
                  contentGroup: "Blog",
                },
                {
                  title:
                    "How Site Search 360 helps streamline medical education in the US",
                  image:
                    "https://images.sitesearch360.com/file/frozenlink/42e3b5780a634c62ed5fcf5a0726b2e3a0b2115f-400x400.jpg",
                  snippet:
                    "As Yousmle's library of content expanded, they needed a way to deliver the right content at the right time.",
                  link: "https://www.sitesearch360.com/case-studies/yousmle-wordpress-search/",
                  contentGroup: "Integrations",
                },
                {
                  title: "How to get started with Site Search 360",
                  image: "/assets/global/search-result-placeholder.svg",
                  snippet:
                    "This guide will walk you through the 4 steps you need to follow in order to start your custom site search journey on the right foot.",
                  link: "https://www.sitesearch360.com/getting-started/",
                  contentGroup: "Getting Started",
                },
              ],
            },
          ],
        },
        queryBased: {
          content: [
            {
              type: "dataSet",
              layout: {
                type: "horizontalList",
                icon: "ss360:search",
              },
              content: [
                { title: "what is site search" },
                { title: "how to add search" },
                { title: "pricing" },
              ],
            },
            {
              type: "resultGroup",
              limit: 3,
              // dataPoints: [
              // 	{ key:  'By', type: 'kv' },
              // 	{ key:  'Date', type: 'kv' }
              // ],
              categoryStyle: "chips",
              layout: {
                showSnippet: true,
                placeholderImage:
                  "/assets/global/search-result-placeholder.svg",
              },
            },
            {
              type: "dataSet",
              layout: {
                type: "horizontalList",
                icon: '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.34314 0.5C1.42647 0.5 0.674845 1.25 0.674845 2.16667L0.668335 12.165C0.668335 13.0859 1.41417 13.8333 2.335 13.8333H3.58337C3.81337 13.8333 4.00004 14.02 4.00004 14.25V15.9883C4.00004 16.7308 4.89757 17.1025 5.42257 16.5775L7.92257 14.0775C8.01257 13.9875 8.12408 13.9279 8.24158 13.887C8.19741 13.5962 8.16671 13.3025 8.16671 13C8.16671 9.77833 10.7784 7.16667 14 7.16667C15.24 7.16667 16.3884 7.55647 17.3334 8.21647V2.16667C17.3334 1.25 16.5834 0.5 15.6667 0.5H2.34314ZM14.0114 8.83333C13.8563 8.83292 13.6986 8.83992 13.5394 8.85612C11.6236 9.05112 10.0512 10.6227 9.85616 12.5394C9.59699 15.0869 11.515 17.1667 14 17.1667C14.8334 17.1667 15.6674 16.916 16.334 16.4993L17.5922 17.7591C17.9147 18.0816 18.4367 18.0816 18.7592 17.7591C19.0817 17.4366 19.0817 16.9146 18.7592 16.5921L17.4994 15.334C17.9161 14.6673 18.1667 13.8333 18.1667 13C18.1667 10.6703 16.3389 8.83955 14.0114 8.83333ZM13.9268 10.5016C15.6506 10.4561 16.9073 12.008 16.378 13.8382C16.1663 14.5724 15.5724 15.1663 14.8383 15.3779C12.7466 15.9821 11.0171 14.2535 11.6221 12.1618C11.8346 11.4276 12.4277 10.8337 13.1618 10.6221C13.4233 10.5465 13.6805 10.5081 13.9268 10.5016Z" fill="#3D8FFF"/></svg>',
              },
              content: [
                {
                  title: "I want to chat with someone",
                  className: "unibox-ac-c",
                  clickHandler() {
                    if ("Beacon" in window) {
                      window.Beacon("open");
                    } else {
                      document.querySelector(".fake-beacon__button").click();
                    }
                    return true;
                  },
                },
              ],
            },
          ],
        },
      },
      equalSearch: true,
      focusLayer: true,
      // focusLayerStyle: 'blur'
    },
    contentGroups: {
      otherName: "Uncategorized",
    },
	allowCookies
  };
  if (!('ss360Configs' in window)) {
    window.ss360Configs = {};
  }
  window.ss360Configs.SS360_MobileSearch = {
      siteId: "sitesearch360.com_11",
      searchBox: {
        searchButtonLabel: "Search",
        placeholder: "Search for features, docs,…",
      },
      suggestions: {
        show: false,
      },
      results: {
        placeholderImage: "/assets/global/search-result-placeholder.svg",
        searchQueryParamName: "mq",
        fullScreenConfig: {
          caption:
            '<img src="/assets/global/search-result-placeholder.svg" alt="Search Site Search 360" style="height:38px;width:64px;" loading="lazy">',
          trigger: ".header__mobile-search-toggle",
        },
      },
      contentGroups: {
        otherName: "Uncategorized",
      },
      layout: {
        navigation: {
          flatTabs: true
        }
      },
	  allowCookies
  };
  let loadTimeout = 0;
  if (document.cookie.indexOf("ss360PluginLoaded=1") === -1 && window.location.href.indexOf('/our-customers') === -1 && window.location.href.indexOf('/customer-gallery') === -1) {
    // delay loading based on connection speed for the first load (in consecutive loads the plugin will be cached, no need to delay here)
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    loadTimeout =
      connection !== undefined && connection.effectiveType !== undefined
        ? connection.effectiveType !== "4g"
          ? 3000
          : 1000
        : 1500;
  }
  setTimeout(() => {
	if (getCookieConsent()) {
		setCookie("ss360PluginLoaded", "1", 31);
	}
	const script = document.createElement("script");
	script.setAttribute("defer", "defer");
	script.setAttribute(
	"src",
	"https://cdn.sitesearch360.com/v14/sitesearch360-v14.min.js"
	);
	document.getElementsByTagName("body")[0].appendChild(script);
  }, loadTimeout);
})();

// Cookie notice
(() => {
	if (getCookieConsent() !== null) {
		return;
	}
	const wrap = document.getElementById('cookie-notice');
	const backdrop = document.getElementById('cookie-notice-backdrop');
	wrap.style.display = 'block';
	backdrop.style.display = 'block';
	const accept = document.getElementById('cookie-accept');
	const decline = document.getElementById('cookie-decline');
	const hide = () => {
		wrap.style.opacity = 0;
		backdrop.style.opacity = 0;
		setTimeout(() => {
			wrap.style.display = 'none';
			backdrop.style.display = 'none';
		}, 300);
	};
	accept.addEventListener('click', () => {
		hide();
		setCookie('ss360CookieConsent', '1', 730);
		setCookie("ss360PluginLoaded", "1", 31);
		!function(){var e=document.createElement("script");e.setAttribute("src","https://www.googletagmanager.com/gtag/js?id=UA-377808-37"),e.setAttribute("async","async"),document.querySelector("head").appendChild(e)}();
		window.SS360.changeConfig('allowCookies', true);
		window.SS360_MobileSearch.changeConfig('allowCookies', true);
	});
	decline.addEventListener('click', () => {
		hide();
		setCookie('ss360CookieConsent', '0', 7);
	});
})();

// Referrer Tracking
(() => {
	if (document.cookie.indexOf('ss360Referrer=') !== -1) {
		return;
	}
	const queryParams = window.location.search.substring(1).split('&').reduce((acc, queryPart) => {
		const kv = queryPart.split('=');
		if (kv.length === 2) {
			acc[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
		}
		return acc;
	}, {});
	let referrer;
	if (queryParams.utm_campaign !== undefined) {
		referrer = queryParams.utm_campaign;
	} else if (queryParams.gclid !== undefined) {
		referrer = `AdWords (${queryParams.gclid})`;
	} else if (document.referrer !== undefined && document.referrer.length > 0) {
		const domain = document.referrer.match(/https?:\/\/([^\/]+)/) !== null ? document.referrer.match(/https?:\/\/([^\/]+)/)[1] : undefined;
		const domainParts = (domain || '').split('.');
		if (domainParts.length > 1 && domainParts[domainParts.length - 2].toLowerCase().trim() !== 'sitesearch360') {
			referrer = document.referrer;
		}
	}
	if (referrer !== undefined && referrer.length > 1) {
		referrer = referrer.replace(/\//g, '---');
		setCookie('ss360Referrer', referrer, 30, '.sitesearch360.com');
		const image = new Image();
		image.src = `https://ecom.sitesearch360.com/referrer/visit/${encodeURIComponent(referrer)}`;
	}
})();


(() => {
  if (!document.querySelector(".js-pricing-card-batman") && !document.querySelector(".js-pricing-table-cell-batman")) {
    return;
  }
  let fixedHeader = null;
  let originalHeader = document.querySelector('.pricing-table__row--header');
  let table = document.querySelector('.pricing-table');
  let headerVisible = true;

  let handleScroll = () => {
    let rect = originalHeader.getBoundingClientRect();
    let tableRect = table.getBoundingClientRect();

    if (rect.top < 70 && headerVisible && tableRect.bottom > 80) {
      // If fixedHeader doesn't exist, create it
      if (fixedHeader === null) {
        // Get widths of original th elements before clone
        let thWidths = [];
        let originalThElements = originalHeader.querySelectorAll('th');
        originalThElements.forEach(th => {
          thWidths.push(th.offsetWidth);
        });

        fixedHeader = originalHeader.cloneNode(true); // deep clone(copy all)
        fixedHeader.style.position = 'fixed';
        fixedHeader.style.top = '70px';
        fixedHeader.style.zIndex = '1000';
        fixedHeader.style.backgroundColor = 'white';
        fixedHeader.style.marginLeft = '-4px';

        let clonedThElements = fixedHeader.querySelectorAll('th');
        clonedThElements.forEach((th, index) => {
          th.style.width = `${thWidths[index]}px`;
        });
        originalHeader.parentNode.insertBefore(fixedHeader, originalHeader);
      }
      // Hide original header and show the fixed
      originalHeader.style.visibility = 'hidden';
      fixedHeader.style.visibility = 'visible';
      headerVisible = false;
    } else if ((rect.top >= 70 || tableRect.bottom <= 80) && !headerVisible) {
      // Show original header and hide the fixed
      originalHeader.style.visibility = 'visible';
      fixedHeader.style.visibility = 'hidden';
      headerVisible = true;
    }
  };
  window.removeEventListener("scroll", handleScroll);
  window.addEventListener("scroll", handleScroll);
})();