/* eslint-disable no-use-before-define */
const PriceUtil = (() => {
	const mathLog10 = Math.log10 ? Math.log10 : (x) => (Math.log(x) / Math.log(10));
    
if (
    !document.querySelector(".js-pricing-card-batman") &&
    !document.querySelector(".js-pricing-table-cell-batman")
) {
    return () => {
        console.error("getCustomPrice was not initialized")
    };
}

const DEFAULT_PRICE_ECOM = 374;
const DEFAULT_PLAN_ECOM = {
	queryLimit: 20000,
	logRetentionDays: 90,
	daysBetweenRecrawls: 1,
	pageLimit: 10000,
	downloadFeed: false,
	apiUsage: true,
	youTube: false,
	imageOptimization: false,
	jsCrawling: false,
	crawlLog: false,
	geoLocation: true,
	searchQualityTracking: true,
	machineLearnedRanking: true,
	backlink: false,
	feedUpdatesPerDay: 1
};

const DEFAULT_PRICE_STANDARD = 119;
const DEFAULT_PLAN_STANDARD = {
	queryLimit: 10000,
	logRetentionDays: 365,
	daysBetweenRecrawls: 7,
	pageLimit: 5000,
	downloadFeed: false,
	apiUsage: true,
	youTube: false,
	imageOptimization: false,
	jsCrawling: false,
	crawlLog: false,
	geoLocation: true,
	searchQualityTracking: true,
	machineLearnedRanking: true,
	backlink: false,
	feedUpdatesPerDay: 1
};

const PAYMENT_PERIOD = {
	MONTH: 'month',
	YEAR: 'year'
};

const PROJECT_TYPE = {
	ECOM: 'ecom',
	STANDARD: 'standard'
};

const DEFAULT_PARAMS = {
	projectType: PROJECT_TYPE.STANDARD,
	paymentPeriod: PAYMENT_PERIOD.MONTH,
	adminCreated: false
};
const getEcomQueriesCost = getLeveledPriceFunction([
	5000,
	25000,
	50000,
	100000,
	500000,
	1000000,
	1500000,
	2000000,
	2500000,
	3000000,
	4000000,
	5000000,
	7500000,
	10000000,
	15000000,
	200000000], 0.6, 0.012);


const getEcomEntriesCost = getLeveledPriceFunction([
	5000,
	10000,
	20000,
	30000,
	40000,
	50000,
	60000,
	70000,
	80000,
	90000,
	100000,
	150000,
	200000,
	250000,
	300000,
	350000,
	400000,
	450000,
	500000,
	600000,
	700000,
	800000,
	900000,
	1000000,
	1200000,
	1400000,
	1600000,
	1800000,
	2000000,
	100000000
], 0.8, 0.008);

const checkDefaultPrice = (customPlan, type) => {
	const DEFAULT_PLAN = type === 'ecom' ? DEFAULT_PLAN_ECOM : DEFAULT_PLAN_STANDARD; 
	const DEFAULT_PRICE = type === 'ecom' ? DEFAULT_PRICE_ECOM : DEFAULT_PRICE_STANDARD; 
	const defaultKeys  = Object.keys(DEFAULT_PLAN);
	let isDefault = true;
	for (let i = 0; i < defaultKeys.length; i++) {
		var k = defaultKeys[i];
		if (customPlan[k] !== DEFAULT_PLAN[k]) {
			isDefault = false;
			break;
		}
	}
	if (isDefault) {
		return DEFAULT_PRICE;
	}
	return null;
};
const mergeWithDefaultPlan = (customPlan, type) => {
	const DEFAULT_PLAN = type === 'ecom' ? DEFAULT_PLAN_ECOM : DEFAULT_PLAN_STANDARD;
	const defaultKeys  = Object.keys(DEFAULT_PLAN);
	const merged = JSON.parse(JSON.stringify(customPlan));
	defaultKeys.forEach(k => {
		if (customPlan[k] === undefined) {
			merged[k] = DEFAULT_PLAN[k];
		}
	});
	return merged;
};
// main export
const getCustomPlanPrice = (customPlan, params = {}) => {
	const paymentPeriod = params.paymentPeriod !== undefined ? params.paymentPeriod : DEFAULT_PARAMS.paymentPeriod;
	const adminCreated = params.adminCreated !== undefined ? params.adminCreated : DEFAULT_PARAMS.adminCreated;
	const projectType = params.projectType  !== undefined ? params.projectType : DEFAULT_PARAMS.projectType;

	if (adminCreated) {
		return getCustomOfferPrice(customPlan, paymentPeriod);
	}
	if (projectType === PROJECT_TYPE.STANDARD) {
		return getStandardSS360CustomPlanPrice(customPlan, paymentPeriod);
	}
	if (projectType === PROJECT_TYPE.ECOM) {
		return getSS360EcomCustomPlanPrice(customPlan, paymentPeriod);
	}
	throw new Error('wrong project type');
};

function getSS360EcomCustomPlanPrice(customPlan, period = PAYMENT_PERIOD.MONTH) {

	const mergedPlan = mergeWithDefaultPlan(customPlan, PROJECT_TYPE.ECOM);
	// let defaultPricefApplied = checkDefaultPrice(mergedPlan, 'ecomm');
	// if (defaultPricefApplied !== null) {
	// 	return defaultPricefApplied;
	// }

	const {
		queryLimit,
		pageLimit,
		logRetentionDays,
		// daysBetweenRecrawls,
		// downloadFeed,
		feedUpdatesPerDay,
		apiUsage,
		// youTube,
		imageOptimization,
		// jsCrawling,
		// crawlLog,
		backlink,
		machineLearnedRanking,
		geoLocation,
		searchQualityTracking
	} = mergedPlan;

	let price = 0;

	// service level
	price += getEcomQueriesCost(queryLimit);
	price += getEcomEntriesCost(pageLimit);
	price += logRetentionCost(logRetentionDays, queryLimit);

	if (apiUsage) {
		price += apiAccessCost();
	}

	if (imageOptimization) {
		price += imageOptimizationCost(pageLimit);
	}

	price += fullUpdatesCost(feedUpdatesPerDay);

	if (geoLocation) {
		price += geoLocationCost();
	}
	if (searchQualityTracking) {
		price += searchQualityTrackingCost(queryLimit);
	}
	if (machineLearnedRanking) {
		price += machineLearnedRankingCost(queryLimit, pageLimit);
	}
	if (backlink) {
		price = applyBacklink(price);
	}
	price = applyPlanPeriod(period, price);
	price = normalize(price);

	return price;
}

function getStandardSS360CustomPlanPrice(customPlan, period = PAYMENT_PERIOD.MONTH) {

	const mergedPlan = mergeWithDefaultPlan(customPlan, PROJECT_TYPE.STANDARD);
	let defaultPriceIfApplied = checkDefaultPrice(mergedPlan, 'standard');
	if (defaultPriceIfApplied !== null) {
		return defaultPriceIfApplied;
	}

	const PRICE_PER_PAGE = 0.6;
	const PRICE_PER_QUERY = 0.35;

	const {
		queryLimit,
		logRetentionDays,
		daysBetweenRecrawls,
		pageLimit,
		downloadFeed,
		apiUsage,
		youTube,
		imageOptimization,
		jsCrawling,
		crawlLog,
		geoLocation,
		searchQualityTracking,
		machineLearnedRanking,
		backlink
	} = mergedPlan;

	let price = Math.round((pageLimit * PRICE_PER_PAGE + queryLimit * PRICE_PER_QUERY) / 200);
	if (price > 2000) {
		price = Math.round(10000 * (1 - Math.exp(-0.00011 * price)));
	}

	price += 10; // flat tax for custom plan

	// analytics retentions 180 days for free, after that every day 10 cents / month
	price += Math.max((logRetentionDays - 180) * 0.1, 0);
	
	// crawls, pricing depends on frequency and number of pages, $0.01 * #pages / interval
	price += (0.01 * pageLimit / daysBetweenRecrawls);
	
	// give 10% discount for monthly re-indexing
	if (daysBetweenRecrawls > 28) {
		price *= 0.95;
	} else if (daysBetweenRecrawls > 6 && pageLimit >= 30000) {
		price *= 0.9;
	}
	
	// javascript crawling $0.015 * #pages / interval
	if (jsCrawling) {
		price += (0.015 * pageLimit / daysBetweenRecrawls);
	}

	// image optimization $0.002 * #pages
	if (imageOptimization) {
		price += (0.002 * pageLimit);
	}

	// youtube for a flat fee of $10
	if (youTube) {
		price += 10;
	}
	
	if (crawlLog) {
		price += 10;
	}

	if (geoLocation) {
		price += geoLocationCost();
	}

	if (machineLearnedRanking) {
		price += machineLearnedRankingCost(queryLimit, pageLimit);
	}

	if (searchQualityTracking) {
		price += searchQualityTrackingCost(queryLimit);
	}
		
	if (apiUsage) {
		if (price < 50) {
			price += 10;
		}
	}

	if (downloadFeed) {
		price *= 1.1;
	}

	if (backlink) {
		price = applyBacklink(price);
	}
	price = applyPlanPeriod(period, price);

	price = normalize(price);
	
	return price;
}

function getCustomOfferPrice(customPlan, period) {
	let price = customPlan.pricePerMonth;
	price = applyPlanPeriod(period, price);
	return normalize(price);
}

function applyBacklink(price) {
	return price * 0.95;
}

function applyPlanPeriod(period, price) {
	if (period === PAYMENT_PERIOD.YEAR) {
		return price * 12 * 0.9;
	}
	return price;
}

function normalize(price) {
	return Math.max(Math.floor(price), 0);
}

function geoLocationCost() {
	return 10;
}

function machineLearnedRankingCost(queries, entries) {
	return ((10 + 40 * Math.max(0.05, mathLog10(queries / 10000))) + 10 * Math.max(1, mathLog10(entries)));
}

function searchQualityTrackingCost(queries) {
	return (10 + 40 * Math.max(0.05, mathLog10(queries / 10000)));
}

function imageOptimizationCost(entries) {
	return 40 * mathLog10(entries / 100);
}

function apiAccessCost() {
	return 40;
}

function logRetentionCost(logRetentionDays, queries) {
	const logRetentionMonths = Math.round((logRetentionDays * 12) / 365);
	return (logRetentionMonths - 3) * 40 * Math.max(0.05, mathLog10(queries / 10000));
}

function fullUpdatesCost(noOfUpdates) {
	return (noOfUpdates - 1) * 50;
}


// to get queries & entries cost for ecomm based on excel spreadsheet
function getLeveledPriceFunction(levels, multiplier, multiplier0) {
	const roundup = fractionNumber => (Math.ceil(fractionNumber * (10 ** 5))) / (10 ** 5);
	const multipliers = [];
	multipliers[0] = multiplier0;
	for (let i = 1; i < levels.length; i++) {
		multipliers[i] = roundup(multipliers[i - 1] * multiplier);
	}

	const sumPricesForLevel = [];
	sumPricesForLevel[0] = levels[0] * multipliers[0];
	for (let i = 1; i < levels.length; i++) {
		sumPricesForLevel[i] = sumPricesForLevel[i - 1] + (levels[i] - levels[i - 1]) * multipliers[i];
	}

	return function (queries) {
		let sum = 0;
		if (queries < levels[0]) {
			return queries * multiplier[0];
		}
		for (let i = 1; i < levels.length; i++) {
			if (queries < levels[i]) {
				sum += sumPricesForLevel[i - 1];
				sum += (queries - levels[i - 1]) * multipliers[i];
				break;
			}
		}
		return sum;
	};
}
const getDefaultPlan = (type) => {
	const defaults = type === 'ecom' ? DEFAULT_PLAN_ECOM : DEFAULT_PLAN_STANDARD
	return JSON.parse(JSON.stringify(defaults));
};
return {getDefaultPlan, getCustomPlanPrice};
})();