(() => {
  var modal = document.getElementById("simpleModal");
  var closeBtn = document.getElementById("closeModalBtn");

  if(!modal || !closeBtn) return;
  
  closeBtn.addEventListener("click", closeModal);
  
  function closeModal() {
    modal.style.display = "none";
  }

  gtag('consent','default', {
    'ad_personalization': 'denied',
    'region': ['EEA'],
  });
  
  window.addEventListener("click", clickOutside);
  
  function clickOutside(e) {
    if (e.target == modal) {
      modal.style.display = "none";
    }
  }
  
  if (!("ss360Configs" in window)) {
    window.ss360Configs = {};
  }
  
  window.ss360Configs.SS360_Gallery = {
    siteId: "sx_live_6244",
    searchBox: {
      selector: "#searchBox",
	  searchButton: '.customer-gallery__search-button',
      placeholder: "Search...",
    },
    results: {
      searchQueryParamName : "gs",
      filters: undefined,
      embedConfig: {
        //			    contentBlock: ".customer-gallery__image"
        contentBlock: "#results",
      },
      resultTemplate: {
        template: `  <div>
      <div class="customer-gallery__header">
        <img
          src="{{dataPointHash.logoimage[0]}}"
          alt="{{ name }}"
          class="customer-gallery__headerLogo"
        />
        <h3 class="customer-gallery__title">{{name}}</h3>
      </div>
      <div class="customer-gallery__image">
        <img
          src="{{ image }}"
          alt="{{ name }}"
          class="customer-gallery__image-img"
        />
        <div class="customer-gallery__hover">
          <ul class="customer-gallery__hover-text">
            <li class="customer-gallery__home">
              <h3 class="customer-gallery__hover-text-header">
                Platform
              </h3>
              <p class="customer-gallery__hover-text-p" id="platform">
                {{ dataPointHash.platform[0] }}
              </p>
            </li>
            <li class="customer-gallery__star">
              <h3 class="customer-gallery__hover-text-header">
                Features
              </h3>
              <p class="customer-gallery__hover-text-p" id="features">
                {{ dataPointHash.features[0] }}
              </p>
            </li>
            <li class="customer-gallery__support">
              <h3 class="customer-gallery__hover-text-header">
                Implementation
              </h3>
              <p class="customer-gallery__hover-text-p" id="implementation">
                {{ dataPointHash.implementation[0] }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="customer-gallery__tags">
            <div class="customer-gallery__category">
                {{ dataPointHash.category[0] }}        
            </div>
            <div class="customer-gallery__sub-category">
                {{ dataPointHash.subcategory[0] }}
            </div>
            <div class="customer-gallery__expand-btn" role="button" aria-label="Show Details">
            <img class="customer-gallery__expand-img" src="https://cdn.sitesearch360.com/customer_gallery/icons/expand_arrows.png" alt="">
            </div>
          </div>    
    </div>`,
        // preRenderCallback(suggest) {
        // },
        postRenderCallback(node, suggest) {
          node.find(".customer-gallery__image").on("click", () => {
            document.getElementById("platform-detail").innerText =
              suggest.dataPointHash.platform[0];
            document.getElementById("implementation-detail").innerText =
              suggest.dataPointHash.implementation[0];
            document.getElementById("features-detail").innerText =
              suggest.dataPointHash.features[0];
            document.getElementById("detail-image").src =
              suggest.dataPointHash.image[0];
            document.getElementById("detail-logo").src =
              suggest.dataPointHash.logoimage[0];
            document.getElementById("detail-company-url").href = suggest.link;
            document.getElementById("detail-company-url").innerText =
              suggest.link;
            modal.style.display = "block";
          });
          node.find(".customer-gallery__expand-btn").on("click", () => {
            document.getElementById("platform-detail").innerText =
              suggest.dataPointHash.platform[0];
            document.getElementById("implementation-detail").innerText =
              suggest.dataPointHash.implementation[0];
            document.getElementById("features-detail").innerText =
              suggest.dataPointHash.features[0];
            document.getElementById("detail-image").src =
              suggest.dataPointHash.image[0];
            document.getElementById("detail-logo").src =
              suggest.dataPointHash.logoimage[0];
            document.getElementById("detail-company-url").href = suggest.link;
            document.getElementById("detail-company-url").innerText =
              suggest.link;
            modal.style.display = "block";
          });
        },
      },
    },
    suggestions: {
      show: true,
      showImages: true,
	  triggersSearch: true,
	  minChars: 0
    },
    filters: {
      enabled: true,
      position: "top"
    },
    layout: {
      desktop: {
        type: "grid",
        gridColsXl: 2,
        gridColsLg: 2,
      },
    },
    callbacks: {
      init() {
        if (window.location.search.indexOf('gs=') === -1) {
          window.SS360_Gallery.showResults("*", {
            shouldPushState: false
          });
        }
      },
	  queryModification(query) {
		return query.trim().length === 0 ? '*' : query;
	  }
    }

  };
})();
